import { useState, useEffect } from "react";
import "./Resume.css";
import { Container, Row, Button } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import resume from "../../../Assets/resume.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Resume(): JSX.Element {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="Resume-Section">
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            href={resume}
            target="_blank"
            style={{ maxWidth: "250px" }}
            className="Btn-Resume-Download"
          >
            <AiOutlineDownload />
            &nbsp;Download Resume&nbsp;
            <AiOutlineDownload />
          </Button>
        </Row>
        <Row className="Resume">
          <Document file={resume} className="d-flex justify-content-center">
            <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
          </Document>
        </Row>
      </Container>
    </div>
  );
}

export default Resume;
