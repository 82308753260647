import "./Preloader.css";

function Preloader(props: any): JSX.Element {
    return (
        <div className="Preloader" id={props.load ? "preloader" : "preloader-none"}>
			
        </div>
    );
}

export default Preloader;
