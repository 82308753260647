import { Row, Col } from "react-bootstrap";
import { FaGithub, FaLinkedin, FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import SocialIcon from "../SocialIcon/SocialIcon";
import "./Contact.css";

function Contact(): JSX.Element {
  return (
    <Row className="Social">
      <Col md={12}>
        <h1>FIND ME ON</h1>
        <ul className="Social-Links">
          <SocialIcon
            href="mailto:contact.web@niv-yul.me"
            children={<MdEmail />}
          ></SocialIcon>
          <SocialIcon
            href="https://www.github.com/Niv-Yulevitch"
            children={<FaGithub />}
          ></SocialIcon>
          <SocialIcon
            href="https://www.linkedin.com/in/niv-yulevitch"
            children={<FaLinkedin />}
          ></SocialIcon>
          <SocialIcon
            href="https://www.facebook.com/niv.yulevitch"
            children={<FaFacebook />}
          ></SocialIcon>
          <SocialIcon
            href="https://www.instagram.com/nivyulevitch"
            children={<RiInstagramFill />}
          ></SocialIcon>
        </ul>
        <p>
          Feel free to{" "}
          <span style={{ fontSize: "1.3em", color: "rgb(117, 167, 242)" }}>
            connect{" "}
          </span>
          with me
        </p>
      </Col>
    </Row>
  );
}

export default Contact;
