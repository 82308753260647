import "./SocialIcon.css";

function SocialIcon(props: {href: string, children: JSX.Element}): JSX.Element {
  return (
    <li className="social-icons">
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer"
        className="icon-color  home-social-icons"
      >
        {props.children}
      </a>
    </li>
  );
}

export default SocialIcon;
