import { Col, Container, Row } from "react-bootstrap";
import "./Home.css";
import homeLogo from "../../../Assets/home-main.png";
import Skills from "../Skills/Skills";
import AboutMe from "../AboutMe/AboutMe";
import Contact from "../Contact/Contact";
import Tools from "../Tools/Tools";

function Home(): JSX.Element {
  return (
    <Container fluid className="Home">
      <Container className="Home-Header">
        <Row>
          <Col md={7} className="Home-Header-Col">
            <h1>Hi, <span className="wave">👋</span> my name is</h1>
            <h2 className="Heading-Name">NIV YULEVITCH</h2>
            <h3 className="Heading-Title">I'm a full-stuck web developer.</h3>
          </Col>
          <Col md={5} style={{ paddingBottom: 20 }}>
            <img
              src={homeLogo}
              alt="home pic"
              className="Home-Pic"
              style={{ maxHeight: "300px" }}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="Home-Content">
        <Container>
          <AboutMe />
          <Skills />
          <Tools />
          <Contact />
        </Container>
      </Container>
    </Container>
  );
}

export default Home;
