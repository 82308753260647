import { useEffect, useState } from "react";
import Header from "../Header/Header";
import Preloader from "../Preloader/Preloader";
import Routing from "../Routing/Routing";
import "./Layout.css";
import Footer from "../Footer/Footer";

function Layout(): JSX.Element {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="Layout">
      <Preloader load={load} />
      <Header />
      <Routing />
      <Footer />
    </div>
  );
}

export default Layout;
