import { Row, Col } from "react-bootstrap";
import "./Skills.css";
import {
  SiJavascript,
  SiNodedotjs,
  SiReact,
  SiTypescript,
  SiAngularjs,
  SiRedux,
  SiExpress,
  SiMongodb,
  SiMysql,
  SiHtml5,
  SiCss3,
  SiBootstrap,
  SiJquery,
  SiPhp,
} from "react-icons/si";
import SkillOrToolIcon from "../SkillOrToolIcon/SkillOrToolIcon";

function Skills(): JSX.Element {
  return (
    <Row fluid className="Skills-Row" style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col md={12} className="Skills-Header">
        <h1 style={{ fontSize: "2.6em" }}>Skills</h1>
      </Col>
      <SkillOrToolIcon children={<SiJavascript />} logoTitle="JavaScript" />
      <SkillOrToolIcon children={<SiTypescript />} logoTitle="TypeScript" />
      <SkillOrToolIcon children={<SiPhp />} logoTitle="PHP" />
      <SkillOrToolIcon children={<SiHtml5 />} logoTitle="HTML5" />
      <SkillOrToolIcon children={<SiCss3 />} logoTitle="CSS3" />
      <SkillOrToolIcon children={<SiReact />} logoTitle="React.js" />
      <SkillOrToolIcon children={<SiAngularjs />} logoTitle="Angular.js" />
      <SkillOrToolIcon children={<SiJquery />} logoTitle="JQuery" />
      <SkillOrToolIcon children={<SiRedux />} logoTitle="Redux" />
      <SkillOrToolIcon children={<SiBootstrap />} logoTitle="Bootstrap" />
      <SkillOrToolIcon children={<SiExpress />} logoTitle="Express" />
      <SkillOrToolIcon children={<SiNodedotjs />} logoTitle="Node.js" />
      <SkillOrToolIcon children={<SiMysql />} logoTitle="MySQL" />
      <SkillOrToolIcon children={<SiMongodb />} logoTitle="MongoDB" />
    </Row>
  );
}

export default Skills;
