import { Col } from "react-bootstrap";
import "./SkillOrToolIcon.css";

function SkillOrToolIcon(props: {
  children: JSX.Element;
  logoTitle: string;
}): JSX.Element {
  return (
    <Col xs={4} md={2} className="SkillsOrTools-Icon">
      {props.children}
      <br />
      {props.logoTitle}
    </Col>
  );
}

export default SkillOrToolIcon;
