import { Row, Col } from "react-bootstrap";
import "./AboutMe.css";

function AboutMe(): JSX.Element {
  const myImg = require("../../../Assets/myImg.png");

  return (
    <Row className="AboutMe">
      <Col md={8}>
        <h1 style={{ fontSize: "2.6em" }}>About Me</h1>
        <p className="AboutMe-Body">
          Hello there! I'm{" "}
          <b style={{ color: "rgb(117, 167, 242)", fontSize: "1.2em" }}>Niv</b>
          , a passionate programmer who's always eager to learn more.
          <br />
          <br />
          I find joy in every line of code I write, whether it's solving complex
          algorithms or crafting elegant user interfaces.
          <br />
          <br />
          My heart lies in building new{" "}
          <b style={{ fontSize: "1.3em" }}>web applications</b>, and I'm
          dedicated to turning ideas into functional, user-friendly websites.
          <br />
          <br />
          With my expertise in{" "}
          <b style={{ fontSize: "1.3em" }}>
            <i>Node.js</i>
          </b>{" "}
          and{" "}
          <b style={{ fontSize: "1.3em" }}>
            {" "}
            <i>Modern Javascript Library and Frameworks</i>
          </b>
          &nbsp; like
          <b style={{ fontSize: "1.3em" }}>
            <i> React.js </i>
          </b>
          and
          <b style={{ fontSize: "1.3em" }}>
            <i> Angular.js</i>
          </b>
          , I'm confident that I can help you create an amazing online presence.
          <br />
          <br />
        </p>
      </Col>
      <Col md={4} className="MyImg">
        <img src={myImg} className="img-fluid" alt="myImage" />
      </Col>
    </Row>
  );
}

export default AboutMe;
