import "./App.css";
import { BrowserRouter } from 'react-router-dom';
import Layout from "./Components/LayoutArea/Layout/Layout";
import { useState, useEffect } from "react";
import Preloader from "./Components/LayoutArea/Preloader/Preloader";
import "bootstrap/dist/css/bootstrap.min.css";

function App(): JSX.Element {
    const [load, upadateLoad] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        upadateLoad(false);
      }, 1200);
  
      return () => clearTimeout(timer);
    }, []);

    return (
        <BrowserRouter>
        <Preloader load={load}/>
            <div className="App" id={load ? "no-scroll" : "scroll"}>
                <Layout />
            </div>
        </BrowserRouter>
    );
}

export default App;
