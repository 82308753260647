import "./Projects.css";

function Projects(): JSX.Element {
    return (
        <div className="Projects">
			<h2>Projects Area</h2>
        </div>
    );
}

export default Projects;
