import { Row, Col } from "react-bootstrap";
import { SiDocker, SiFirebase, SiGit, SiGithub, SiMacos, SiPostman, SiVisualstudiocode, SiXampp } from "react-icons/si";
import "./Tools.css";
import SkillOrToolIcon from "../SkillOrToolIcon/SkillOrToolIcon";

function Tools(): JSX.Element {
  return (
    <Row fluid className="Tools-Row">
      <Col md={12} className="Tools-Header">
        <h1 style={{ fontSize: "2.6em" }}>Tools</h1>
      </Col>
      <SkillOrToolIcon children={<SiMacos />} logoTitle="MacOS" />
      <SkillOrToolIcon children={<SiVisualstudiocode />} logoTitle="Visual Studio Code" />
      <SkillOrToolIcon children={<SiPostman />} logoTitle="Postman" />
      <SkillOrToolIcon children={<SiXampp />} logoTitle="XAMPP" />
      <SkillOrToolIcon children={<SiGit />} logoTitle="GIT" />
      <SkillOrToolIcon children={<SiGithub />} logoTitle="GITHub" />
      <SkillOrToolIcon children={<SiFirebase />} logoTitle="Firebase" />
      <SkillOrToolIcon children={<SiDocker />} logoTitle="Docker" />
    </Row>
  );
}

export default Tools;
