import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import { FaGithub, FaLinkedin, FaFacebook,  } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

function Footer(): JSX.Element {
    let year = new Date().getFullYear();

    return (
        <Container fluid className="Footer">
            <Row>
                <Col md="4" className="Footer-Copyright">
                </Col>
                <Col md="4" className="Footer-Copyright">
                    <h3>Designed & Built by Niv Yulevitch | Copyright © {year}</h3>
                </Col>
                <Col md="4" className="Footer-Body">
                    <ul className="Footer-Icons">
                        <li className="Social-Icons">
                            <a href="https://www.github.com/Niv-Yulevitch" style={{color: "white"}} target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                        </li>
                        <li className="Social-Icons">
                            <a href="https://www.linkedin.com/in/niv-yulevitch" style={{color: "white"}} target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        </li>
                        <li className="Social-Icons">
                            <a href="https://www.facebook.com/niv.yulevitch" style={{color: "white"}} target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                        </li>
                        <li className="Social-Icons">
                            <a href="https://www.instagram.com/nivyulevitch" style={{color: "white"}} target="_blank" rel="noopener noreferrer"><RiInstagramFill /></a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
